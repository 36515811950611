export const firebaseEventKey = {
  trost_mindcheck_check_click: ({type}) => ({
    params: {
      event_label: 'trost_mindcheck_check_click',
      type,
    },
  }),
  trost_mindcheck_answer_click: ({type}) => ({
    params: {
      event_label: 'trost_mindcheck_answer_click',
      type,
    },
  }),
  trost_mindcheck_answer_complete: ({type}) => ({
    params: {
      event_label: 'trost_mindcheck_answer_complete',
      type,
    },
  }),
  trost_mindcheck_result_view: ({type}) => ({
    params: {
      event_label: 'trost_mindcheck_result_view',
      type,
    },
  }),
};
